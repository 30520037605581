import * as httpClient from './capacitor-http-client'
import type { UserProfile } from '@/types/UserProfile'

const baseUrl = '/profile'

const show = () => httpClient.get<UserProfile>(baseUrl).then((response) => response.data)

const store = (profile?: Partial<UserProfile>) => httpClient.post(baseUrl, profile).then((response) => response.data)

const update = (profile?: Partial<UserProfile>) => httpClient.put(baseUrl, profile).then((response) => response.data)

const profileServices = { show, store, update }
export default profileServices
